import React from 'react';

export const Preset = ({
  description,
  value,
  handleOnChange,
  isBest = false,
  disabled = false,
  isPresetActive = false,
}) => {
  const onChange = () => handleOnChange(value, isBest, description);

  return (
    <button
      data-value={value}
      className={`btn btn-primary custom-btn ${isPresetActive ? 'active' : undefined}`}
      onClick={onChange}
      disabled={disabled}
    >
      {description}
    </button>
  );
};
