import moment from 'moment';

export const NAVIGATOR_SNAP_ERROR_MARGIN_IN_DAYS = 15;

export const ACTIONS = {
  UPDATE_STATE: 'UPDATE_STATE',
  DATE_UPDATE: 'DATE_UPDATE',
  FILTER_UPDATE: 'FILTER_UPDATE',
  UPDATE_INPUT: 'UPDATE_INPUT',
  UPDATE_BY_NAVIGATOR: 'UPDATE_BY_NAVIGATOR',
  UPDATE_BY_FILTERS: 'UPDATE_BY_FILTERS',
  SET_PRESET: 'SET_PRESET'
};

function navigatorShouldStickOnBoundary (min, max, range, dateBoundaries) {
  const { min: minEdge, max: maxEdge } = dateBoundaries;
  const errorMarginDays = NAVIGATOR_SNAP_ERROR_MARGIN_IN_DAYS;
  const diffInDaysOnMin = Math.abs(moment(min).diff(minEdge, 'days'));
  const diffInDaysOnMax = Math.abs(moment(maxEdge).diff(max, 'days'));
  if (diffInDaysOnMin < errorMarginDays) {
    return {
      stickOnEdge: true,
      min: minEdge,
      max: moment(minEdge)
        .add(range, 'y')
        .valueOf()
    };
  } else if (diffInDaysOnMax < errorMarginDays) {
    return {
      stickOnEdge: true,
      min: moment(maxEdge)
        .subtract(range, 'y')
        .valueOf(),
      max: maxEdge
    };
  }
  return { stickOnEdge: false, min, max };
}

export function reducer (prevState, { type, state }) {
  const {
    min: prevMin,
    max: prevMax,
    index: prevIndex,
    range: prevRange,
    updatedBy: prevUpdatedBy
  } = prevState;

  const { min, max, range, dateBoundaries } = state;

  let stateValue = null;

  switch (type) {
    case ACTIONS.SET_PRESET: {
      stateValue = {
        ...prevState,
        ...state,
        dragger: false,
        shouldUpdate: true,
        range
      };

      return stateValue;
    }
    case ACTIONS.DATE_UPDATE: {
      stateValue = {
        ...prevState,
        ...state,
        dragger: false,
        shouldUpdate: true,
        range
      };

      if (range === -1) {
        stateValue.shouldUpdate = true;
        stateValue.min = dateBoundaries.min;
        stateValue.max = dateBoundaries.max;
        return stateValue;
      }

      const {
        stickOnEdge,
        min: minNewEdge,
        max: maxNewEdge
      } = navigatorShouldStickOnBoundary(min, max, range, dateBoundaries);

      if (stickOnEdge) {
        stateValue.min = minNewEdge;
        stateValue.max = maxNewEdge;
        stateValue.dragged = true;
        return stateValue;
      } else if (min !== prevMin && max === prevMax) {
        const minEdge = moment(prevMax)
          .subtract(range, 'y')
          .valueOf();
        stateValue.min = minEdge;
        stateValue.max = max;
        return stateValue;
      } else if (max !== prevMax && min === prevMin) {
        const maxEdge = moment(prevMin)
          .add(range, 'y')
          .valueOf();
        stateValue.max = maxEdge;
        stateValue.min = min;
        return stateValue;
      } else if (moment(max).diff(min, 'y', true) !== parseFloat(range)) {
        const minEdgeDiff = Math.abs(moment(prevMin).diff(min, 'y', true));
        const maxEdgeDiff = Math.abs(moment(prevMax).diff(max, 'y', true));

        stateValue.dragged = true;
        if (maxEdgeDiff > minEdgeDiff) {
          const maxEdge = moment(min)
            .add(range, 'y')
            .valueOf();
          stateValue.min = min;
          stateValue.max = maxEdge;
          return stateValue;
        }
        const minEdge = moment(max)
          .subtract(range, 'y')
          .valueOf();
        stateValue.min = minEdge;
        stateValue.max = max;
        return stateValue;
      }
      stateValue.max = max;
      stateValue.min = min;
      stateValue.dragged = true;
      stateValue.shouldUpdate = !(prevMin === min && prevMax === max);
      return stateValue;
    }
    case ACTIONS.FILTER_UPDATE: {
      const {
        range: newRange,
        min: startDate,
        dateBoundaries: { max: endEdge, min: startEdge },
        index,
        isPresetClicked,
      } = state;

      stateValue = {
        ...prevState,
        range: newRange,
        dragged: false,
        shouldUpdate: false,
        index,
        isPresetClicked,
      };

      if (prevUpdatedBy === ACTIONS.UPDATE_BY_NAVIGATOR) {
        stateValue.dragged = false;
      }

      if (prevUpdatedBy === ACTIONS.UPDATE_BY_NAVIGATOR) {
        stateValue.updatedBy = ACTIONS.UPDATE_BY_FILTERS;
        return stateValue;
      }

      if (
        prevRange === newRange &&
        prevMin === startDate &&
        prevIndex === index
      ) {
        return stateValue;
      }

      if (newRange === -1) {
        stateValue.min = startEdge;
        stateValue.max = endEdge;
        stateValue.shouldUpdate = true;
        return stateValue;
      }

      if (prevIndex !== index) {
        stateValue.min = startEdge;
        stateValue.max = moment(startEdge)
          .add(newRange, 'y')
          .valueOf();
        stateValue.shouldUpdate = true;
        return stateValue;
      }

      const newMinEdge = moment(max)
        .subtract(newRange, 'y')
        .valueOf();
      const newMaxEdge = moment(min)
        .add(newRange, 'y')
        .valueOf();

      stateValue.dragged = !isPresetClicked;
      stateValue.shouldUpdate = true;

      if (newMaxEdge > endEdge) {
        stateValue.max = max;
        stateValue.min = newMinEdge;
      } else {
        stateValue.min = min;
        stateValue.max = newMaxEdge;
      }
      return stateValue;
    }
    default:
      return prevState;
  }
}
