import React from 'react';
import TagManager from 'react-gtm-module';
import { Layout } from './screens/Layout';
import Home from './screens/Home';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import NotFound from './screens/NotFound';

export default function App() {
  // Google tag manager
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_CONTAINER_ID
  };

  TagManager.initialize(tagManagerArgs);

  return (
    <Router>
      <Layout>
        <Switch>
          <Route exact path='/not-found' component={NotFound} />
          <Route exact path='/' component={Home} />
          <Redirect from='*' to='/not-found' />
        </Switch>
      </Layout>
    </Router>
  );
}