import React from 'react';
import PropTypes from 'prop-types';

import SvgContainer from '../SvgContainer';

const BlackLogo = ({ width, height }) => (
  <SvgContainer width={width} height={height}>
    <g>
      <g>
        <g>
          <g>
            <path d='M49.009 24.21c-.602-.101-1.17-.168-1.772-.168-.602 0-1.17.067-1.773.167-.635-2.307-2.24-5.986-3.445-8.962-1.437-.034-2.909-.067-4.38-.067-1.371 0-2.71.033-4.08.067-1.405 3.445-2.676 6.856-3.244 8.962-.435-.1-.836-.167-1.238-.167-.4 0-.802.067-1.237.167A1222.84 1222.84 0 0 0 38.308.7h.635c3.277 7.758 8.294 19.931 10.066 23.51zM37.839 5.213l-3.645 8.504c1.17 0 2.442.067 3.612.067 1.237 0 2.508-.033 3.745-.067z' />
          </g>
          <g>
            <path d='M53.017 9.791a238.9 238.9 0 0 0-.1-6.922c-2.308 0-5.284.134-7.157.368.1-.334.167-.702.167-1.07 0-.334-.067-.669-.167-1.037 2.876.1 5.752.168 8.628.168 2.876 0 5.752-.068 8.628-.168-.1.368-.167.703-.167 1.07 0 .335.067.703.167 1.037-1.873-.234-4.849-.368-7.157-.368a238.9 238.9 0 0 0-.1 6.922v5.753c0 2.875.134 5.786.268 8.66a9.393 9.393 0 0 0-1.639-.166c-.535 0-1.07.067-1.639.167.134-2.875.268-5.786.268-8.661V9.79' />
          </g>
          <g>
            <path d='M83.018 9.791c0-2.875-.133-5.785-.267-8.661.568.1 1.104.168 1.638.168.535 0 1.071-.068 1.639-.168-.134 2.876-.267 5.786-.267 8.661v5.753c0 2.875.133 5.786.267 8.66a9.388 9.388 0 0 0-1.639-.166c-.534 0-1.07.067-1.638.167.134-2.875.267-5.786.267-8.661v-3.01c-2.34-.034-4.648-.067-6.989-.067-2.34 0-4.648.033-6.99.067v3.01c0 2.875.134 5.786.268 8.66a9.393 9.393 0 0 0-1.638-.166c-.535 0-1.07.067-1.639.167.134-2.875.268-5.786.268-8.661V9.79c0-2.875-.134-5.785-.268-8.661.568.1 1.104.168 1.639.168.535 0 1.07-.068 1.638-.168-.134 2.876-.267 5.786-.267 8.661v1.171c2.34.034 4.648.1 6.99.1 2.34 0 4.648-.066 6.988-.1v-1.17' />
          </g>
          <g>
            <path d='M90.977 9.758c0-2.876-.134-5.785-.267-8.628 2.006.1 3.979.168 5.985.168 2.007 0 3.98-.068 5.954-.168a5.079 5.079 0 0 0 0 2.007c-2.81-.2-4.449-.435-8.797-.435-.032 1.438-.132 2.608-.132 4.247 0 1.74.033 3.579.033 4.415 3.946 0 6.153-.168 8.126-.335-.067.335-.1.702-.1 1.037 0 .334.033.669.1 1.003-2.307-.234-6.52-.301-8.126-.301-.033.803-.033 1.639-.033 4.849 0 2.542.033 4.18.066 5.016 2.977 0 6.923-.033 9.03-.434-.067.3-.1.735-.1 1.037 0 .3.033.668.1.969-1.974-.1-3.98-.167-6.02-.167-2.04 0-4.047.067-6.086.167.133-2.91.267-5.82.267-8.694V9.758' />
          </g>
          <g>
            <path d='M125.185 24.644c-5.083-5.986-11.17-12.407-16.888-18.493-.034 1.237-.1 3.244-.1 6.788 0 2.877.133 9.933.3 11.27-.367-.1-.768-.167-1.17-.167-.401 0-.802.067-1.237.167.267-1.572.702-10.133.702-14.547 0-4.214 0-7.457-.1-8.962h.535c5.45 5.919 12.841 13.979 16.922 18.091 0-5.985 0-12.507-.268-17.657.367.1.769.168 1.136.168.368 0 .704-.067 1.071-.168-.2 1.974-.535 8.595-.535 11.204 0 3.41.067 10.801.1 12.306h-.468' />
          </g>
          <g>
            <path d='M130.368 9.758c0-2.876-.134-5.785-.268-8.628 2.007.1 3.98.168 5.987.168 2.005 0 3.979-.068 5.952-.168-.066.335-.1.67-.1 1.004 0 .334.034.668.1 1.003-2.809-.2-4.448-.435-8.795-.435-.034 1.438-.134 2.608-.134 4.247 0 1.74.033 3.579.033 4.415 3.946 0 6.153-.168 8.126-.335-.066.335-.1.702-.1 1.037 0 .334.034.669.1 1.003-2.307-.234-6.52-.301-8.126-.301-.033.803-.033 1.639-.033 4.849 0 2.542.033 4.18.066 5.016 2.977 0 6.923-.033 9.03-.434-.067.3-.1.735-.1 1.037 0 .3.033.668.1.969-1.973-.1-3.98-.167-6.02-.167-2.04 0-4.046.067-6.086.167.134-2.91.268-5.82.268-8.694V9.758' />
          </g>
          <g>
            <path d='M3.243 3.23A20.477 20.477 0 0 0 .58 5.264c7.635 1.325 13.825 5.143 17.629 10.63C16.12 9.771 11.259 5.414 3.243 3.23' />
          </g>
          <g>
            <path d='M8.762.75c-1.408.392-2.781.94-4.082 1.635 10.093 3.129 14.07 9.603 15.382 15.818C20.038 11.11 16.256 5.26 8.762.75' />
          </g>
          <g>
            <path d='M21.134 20.622C23.29 11 18.535 4.09 14.368.096a15.863 15.863 0 0 0-.81-.026 17.46 17.46 0 0 0-3.128.294c9.39 6.101 11.352 13.746 10.704 20.258' />
          </g>
          <g>
            <path d='M21.96 22.25c2.991-6.48 2.766-13.298-.658-20.058A14.686 14.686 0 0 0 16.26.31c3.944 4.239 8.553 11.742 5.7 21.94' />
          </g>
          <g>
            <path d='M26.578 7.446a16.343 16.343 0 0 0-3.324-3.856c2.909 7.033 2.562 14.223-1.064 20.86a24.797 24.797 0 0 0 4.374-11.73c.186-1.808.206-3.538.014-5.274' />
          </g>
        </g>
      </g>
    </g>
  </SvgContainer>
);

const ColorLogo = ({ width, height, className }) => (
  <SvgContainer width={width} height={height} className={className} viewBox='0 0 296.67 49.68'>
    <g id='Layer_2' data-name='Layer 2'>
      <g id='Layer_2-2' data-name='Layer 2'>
        <path d='M77.57,1.26C84.19,17,94.34,41.56,97.92,48.8a19.07,19.07,0,0,0-7.17,0c-1.28-4.67-4.53-12.1-7-18.12-2.91-.07-5.88-.14-8.86-.14-2.77,0-5.48.07-8.25.14A182.19,182.19,0,0,0,60.12,48.8a9.36,9.36,0,0,0-5,0C62.36,33,69.39,17.22,76.28,1.26Zm-2.23,9.13L68,27.57c2.36,0,4.93.13,7.3.13s5.07-.07,7.57-.13Z' />
        <path d='M106,19.66q0-7-.2-14a135.88,135.88,0,0,0-14.47.74,7.68,7.68,0,0,0,.34-2.16,8.12,8.12,0,0,0-.34-2.1c5.81.21,11.63.34,17.44.34s11.63-.13,17.45-.34a8.18,8.18,0,0,0-.34,2.17,7.61,7.61,0,0,0,.34,2.09,135.88,135.88,0,0,0-14.47-.74q-.21,7-.2,14V31.29c0,5.81.27,11.69.54,17.51a16.33,16.33,0,0,0-6.63,0c.27-5.82.54-11.7.54-17.51Z' />
        <path d='M166.68,19.66c0-5.82-.27-11.7-.54-17.52a18.32,18.32,0,0,0,3.31.34,18.34,18.34,0,0,0,3.32-.34c-.27,5.82-.54,11.7-.54,17.52V31.29c0,5.81.27,11.69.54,17.51a16.33,16.33,0,0,0-6.63,0c.27-5.82.54-11.7.54-17.51V25.2c-4.73-.07-9.4-.13-14.13-.13s-9.4.06-14.13.13v6.09c0,5.81.27,11.69.54,17.51a16.33,16.33,0,0,0-6.63,0c.27-5.82.54-11.7.54-17.51V19.66c0-5.82-.27-11.7-.54-17.52a18.34,18.34,0,0,0,3.32.34A18.32,18.32,0,0,0,139,2.14c-.27,5.82-.54,11.7-.54,17.52V22c4.73.07,9.4.21,14.13.21s9.4-.14,14.13-.21Z' />
        <path d='M182.77,19.59c0-5.82-.27-11.7-.54-17.45,4.06.21,8.05.34,12.1.34s8-.13,12-.34a10.05,10.05,0,0,0-.2,2,10,10,0,0,0,.2,2c-5.68-.41-9-.88-17.78-.88-.07,2.91-.27,5.28-.27,8.59,0,3.51.06,7.23.06,8.92,8,0,12.44-.33,16.43-.67a11.38,11.38,0,0,0-.2,2.09,10.66,10.66,0,0,0,.2,2c-4.66-.47-13.18-.61-16.43-.61-.06,1.63-.06,3.32-.06,9.81,0,5.14.06,8.45.13,10.14,6,0,14-.07,18.26-.88a10.68,10.68,0,0,0-.21,2.1,9.33,9.33,0,0,0,.21,2c-4-.2-8.05-.34-12.17-.34s-8.19.14-12.31.34c.27-5.88.54-11.77.54-17.58Z' />
        <path d='M251.94,49.68c-10.28-12.11-22.58-25.09-34.15-37.39-.06,2.5-.2,6.55-.2,13.72,0,5.82.27,20.08.61,22.79a9.23,9.23,0,0,0-2.37-.34,11.27,11.27,0,0,0-2.5.34c.54-3.18,1.42-20.49,1.42-29.41,0-8.52,0-15.08-.2-18.13h1.08c11,12,26,28.27,34.21,36.58,0-12.1,0-25.28-.54-35.7a8.77,8.77,0,0,0,2.3.34,7.85,7.85,0,0,0,2.17-.34c-.41,4-1.09,17.38-1.09,22.65,0,6.9.14,21.84.21,24.89Z' />
        <path d='M262.42,19.59c0-5.82-.27-11.7-.54-17.45,4.05.21,8,.34,12.1.34s8-.13,12-.34a9.92,9.92,0,0,0,0,4.06c-5.68-.41-9-.88-17.79-.88-.06,2.91-.27,5.28-.27,8.59,0,3.51.07,7.23.07,8.92,8,0,12.44-.33,16.43-.67a10.57,10.57,0,0,0-.2,2.09,9.91,9.91,0,0,0,.2,2c-4.67-.47-13.18-.61-16.43-.61C268,27.3,268,29,268,35.48c0,5.14.07,8.45.14,10.14,6,0,14-.07,18.25-.88a11.49,11.49,0,0,0-.2,2.1,10,10,0,0,0,.2,2c-4-.2-8-.34-12.17-.34s-8.18.14-12.3.34c.27-5.88.54-11.77.54-17.58Z' />
        <path
          fill='#5091cb'
          d='M5.38,6.38A41.36,41.36,0,0,0,0,10.49C15.44,13.17,28,20.89,35.64,32,31.42,19.61,21.59,10.8,5.38,6.38Z'
        />
        <path
          fill='#5091cb'
          d='M16.55,1.37a40.81,40.81,0,0,0-8.26,3.3c20.41,6.33,28.45,19.42,31.1,32C39.34,22.31,31.7,10.49,16.55,1.37Z'
        />
        <path
          fill='#5091cb'
          d='M41.55,41.55C45.91,22.1,36.3,8.13,27.87.05c-.54,0-1.08,0-1.63,0a35.5,35.5,0,0,0-6.33.59C38.9,12.93,42.86,28.39,41.55,41.55Z'
        />
        <path
          fill='#5091cb'
          d='M43.23,44.84c6-13.1,5.59-26.88-1.34-40.55A29.71,29.71,0,0,0,31.7.48C39.67,9.05,49,24.23,43.23,44.84Z'
        />
        <path
          fill='#5091cb'
          d='M52.57,14.92a33,33,0,0,0-6.72-7.8C51.73,21.34,51,35.88,43.7,49.3a50.07,50.07,0,0,0,8.84-23.72A49.36,49.36,0,0,0,52.57,14.92Z'
        />
        <path d='M294,2.15a2.62,2.62,0,1,1-2.65,2.63A2.64,2.64,0,0,1,294,2.15ZM294,7a2.23,2.23,0,1,0-2.23-2.23A2.24,2.24,0,0,0,294,7Zm-1-3.72H294c.55,0,1.1.23,1.1.87a.79.79,0,0,1-.69.8l.8,1.3h-.65L293.91,5h-.38V6.26H293Zm1,1.3c.3,0,.64-.08.64-.44s-.31-.41-.59-.41h-.48v.85Z' />
      </g>
    </g>
    </SvgContainer>
);

export function AtheneLogo ({ blackLogo, ...props }) {
  const Component = blackLogo ? BlackLogo : ColorLogo;

  return <Component {...props} />;
}

AtheneLogo.propTypes = {
  blackLogo: PropTypes.bool,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired
};

AtheneLogo.defaultProps = {
  blackLogo: false,
  width: 143,
  height: 25
};
