import FontFaceObserver from 'fontfaceobserver';

import moment from 'moment';

import { SCREEN_BREAKPOINTS } from '../constants';

export const tryLoadFonts = async () => {
	try {
		await Promise.all([
			new FontFaceObserver('Gotham-Book').check(),
			new FontFaceObserver('Gotham-Bold').check(),
			new FontFaceObserver('Gotham-Medium').check(),
			new FontFaceObserver('Gotham-Light').check(),
			new FontFaceObserver('Chronicle Text G1 Italic').check(),
			new FontFaceObserver('ProximaNova-Regular').check()
		]);
	} catch (err) {
		console.warn('Could not load fonts', err);
	}
};

export const parseDatesForData = (source) => {
	Object.values(source).forEach(index => {
		index.forEach((item) => {
			item.date = moment(item.date);
		});
	});
};

export const isWeekend = date => {
	const weekday = moment(date).format('dddd');
	return weekday === 'Sunday' || weekday === 'Saturday';
};

export const calculateCompoundAnnualReturn = ({
	startValue,
	endValue,
	years
}) => {
	const result = (Math.pow(endValue / startValue, (1 / years)) - 1) * 100;
	return result.toFixed(2);
};

export const calculateCompoundAnnualReturnByDates = ({
	start,
	end,
	minDifference = 1
}) => {
	let diff = end.date.diff(start.date, 'years', true);

	diff = diff < minDifference ? minDifference : diff;

	return calculateCompoundAnnualReturn({
		startValue: start.value,
		endValue: end.value,
		years: diff
	});
};

export const searchCompoundAnnualReturn = ({
	source,
	index,
	startDate,
	endDate
}) => {
	const rows = source[index];
	const segment = rows.filter(row => {
		return row.date.valueOf() >= startDate.valueOf() && row.date.valueOf() <= endDate.valueOf();
	});

	if (segment.length === 0) return 0;

	const first = segment[0];
	const last = segment[segment.length - 1];

	const annualReturn = calculateCompoundAnnualReturnByDates({
		start: first,
		end: last
	});

	return annualReturn;
};

export const searchSegmentByRange = ({
	source,
	index,
	startDate,
	endDate
}) => {
	let rows = source[index];
	rows = rows.filter((row) => {
		return row.date.valueOf() >= startDate.valueOf() && row.date.valueOf() <= endDate.valueOf();
	});
	return rows;
};

export const getScreenBreakpoint = ({ width }) => {
	if (width <= 575) return SCREEN_BREAKPOINTS.XS;
	if (width >= 576 && width <= 767) return SCREEN_BREAKPOINTS.SM;
	if (width >= 768 && width <= 991) return SCREEN_BREAKPOINTS.MD;
	if (width >= 992 && width <= 1199) return SCREEN_BREAKPOINTS.LG;
	return SCREEN_BREAKPOINTS.XL;
};

export function removeTime(date) {
	date = date instanceof Date ? date : new Date(date);
	return moment(moment(date).format('LL'), 'LL').valueOf();
}