import { doRequest } from '../utils/apiHelper';

export async function fetchConfigurations() {
	try {
		const response = await doRequest({
			url: 'api/Configurations',
			method: 'GET',
		});

		return response;
	} catch (err) {
		console.error('Error trying to fetch the configurations', err);
		return null;
	}
}

export async function fetchData() {
	try {
		const response = await doRequest({
			url: 'api/Configurations/data',
			method: 'GET',
		});

		return response;
	} catch (err) {
		console.error('Error trying to fetch the configurations', err);
		return null;
	}
}
