const ATHENE_BCA = 'https://www.athenebca.com';

export const links = [
  { href: `${ATHENE_BCA}/athene/`, text: 'ABOUT ATHENE' },
  { href: `${ATHENE_BCA}/bca/`, text: 'ACCUMULATION' },
  {
    href: '#income',
    text: 'INCOME',
    links: [
      { href: `${ATHENE_BCA}/flex-growth/`, text: 'Flex Growth<sup>&reg;</sup>' },
      { href: `${ATHENE_BCA}/sgo-max/`, text: 'Stacked Growth Option Max<sup>&reg;</sup>' }
    ]
  },
  {
    text: 'LEGACY',
    links: [
      {
        href: `${ATHENE_BCA}/fer-max/`,
        text: 'Family Endowment Rider<sup>&reg;</sup> Max'
      },
      {
        href: `${ATHENE_BCA}/fer/`,
        text: 'Family Endowment Rider<sup>&reg;</sup>'
      }
    ]
  },
  {
    text: 'CLIENT TOOLS',
    links: [
      {
        href: 'https://athene-fer.azurewebsites.net/',
        text: 'RMD SOLUTION'
      },
      {
        href: 'https://athene-bca-ie.myannexus.com/',
        text: 'Index Explorer'
      },
      {
        href: 'https://athene-bca-soe.myannexus.com/',
        text: 'Strategy Option Explorer'
      }
    ]
  },
  {
    text: 'INDEX OPTIONS',
    links: [
      {
        href: `${ATHENE_BCA}/citi-grandmaster-index/`,
        text: 'Citi Grandmaster Index'
      },
      {
        href: `${ATHENE_BCA}/shiller-barclays-cape-allocator-6-index/`,
        text: 'Shiller Barclays CAPE<sup>&reg;</sup> Allocator 6 Index'
      },
      {
        href: `${ATHENE_BCA}/wisdomtree-siegel-strategic-value-index/`,
        text: 'WisdomTree Siegel Strategic Value Index'
      },
      {
        href: `${ATHENE_BCA}/shiller-barclays-global/`,
        text: 'Shiller Barclays Global Index'
      },
      {
        href: `${ATHENE_BCA}/merrill-lynch-rpm-index/`,
        text: 'Merril Lynch RPM Index<sup>&trade;</sup>'
      },
    ]
  }
];
