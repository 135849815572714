import React, {
  useEffect,
  useState,
  createRef,
  useCallback,
  useReducer,
  useImperativeHandle,
  forwardRef
} from 'react';
import moment from 'moment';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

import {
  getChartConfig,
  formatChartData,
  roundByNum,
  drawTicks
} from './helper';
import { renderOutline, renderNavigatorHandles } from './customRenders';
import { STOCK_CHART_MAX_NUMBER_OFF_YEARS } from '../../../constants';
import { useWindowResize } from '../../../utils/useWindowResize';

import 'react-block-ui/style.css';
import 'loaders.css/loaders.min.css';

import './styles.scss';
import { ACTIONS, reducer } from './reducer';
import { getScreenBreakpoint } from '../../../utils';

require('highcharts/highcharts-more')(Highcharts);
renderOutline(Highcharts);
renderNavigatorHandles(Highcharts);

export function StockChart (
  {
    configurations: { indexes, ranges },
    endDate,
    startDate,
    setEndDate,
    setStartDate,
    rangeSelected,
    indexSelected: index,
    dateBoundaries: incomingDateBoundaries,
    setRangeSelected,
    indexEvaluationData,
    setPresetSelected,
    isPresetClicked,
    setIsPresetClicked,
  },
  ref
) {
  const chartRef = createRef();
  const screenSize = useWindowResize();
  const screenBreakpoint = getScreenBreakpoint(screenSize);
  const [chartOptions, setChartOptions] = useState(null);

  const range = parseInt(rangeSelected);
  const dateBoundaries = {
    min: incomingDateBoundaries.start.valueOf(),
    max: incomingDateBoundaries.end.valueOf()
  };

  const [navigatorState, dispatchNavigatorState] = useReducer(reducer, {
    min: dateBoundaries.min,
    max:
      range === -1
        ? dateBoundaries.min
        : moment(dateBoundaries.start)
            .add(range, 'y')
            .valueOf(),
    dragger: false,
    shouldUpdate: false,
    updatedBy: null,
    index,
    range,
    isPreset: false
  });

  useImperativeHandle(ref, () => ({
    setPreset: state => {
      dispatchNavigatorState({ type: ACTIONS.SET_PRESET, state });
    }
  }));

  useEffect(() => {
    if (startDate && endDate && range && index) {
      dispatchNavigatorState({
        type: ACTIONS.FILTER_UPDATE,
        state: {
          index,
          range,
          min: startDate.valueOf(),
          max: endDate.valueOf(),
          dateBoundaries,
          isPresetClicked,
        }
      });
    }
  }, [startDate, endDate, range, index]);

  useEffect(() => {
    if (navigatorState.shouldUpdate) {
      if (chartRef.current) {
        const { min, max, range } = navigatorState;
        const { chart } = chartRef.current;

        chart.xAxis[0].setExtremes(min, max);
        drawTicks.call(chart, screenSize, min, max);

        setStartDate(moment(min).toDate());
        setEndDate(moment(max).toDate());
        setRangeSelected(range.toString());
        setIsPresetClicked(false);
      }
    }
  }, [navigatorState]);

  useEffect(() => {
    if (navigatorState.dragged && !isPresetClicked) {
      setPresetSelected('');
    }
  }, [navigatorState, isPresetClicked]);

  const getValidYearRange = useCallback(
    ({ max, min }) => {
      const targetYear = roundByNum(moment(max).diff(min, 'y', true), 1);
      const rangeValues = ranges
        .filter(datum => datum.value !== '-1')
        .map(datum => parseInt(datum.value));

      const highestOption =
        Math.max(null, ...rangeValues) +
        Math.floor(STOCK_CHART_MAX_NUMBER_OFF_YEARS / 4);
      if (targetYear > highestOption) return -1;
      const [nearestYear] = rangeValues.sort(
        (a, b) => Math.abs(targetYear - a) - Math.abs(targetYear - b)
      );
      return nearestYear;
    },
    [ranges]
  );

  useEffect(() => {
    let redrawTimeout;

    if (indexEvaluationData && startDate && endDate) {
      const data = formatChartData(indexEvaluationData);
      const [selectedIndex] = indexes.filter(_index => _index.value === index);
      const options = getChartConfig(
        data,
        dateBoundaries,
        getValidYearRange,
        selectedIndex.stockTicker,
        dispatchNavigatorState,
        screenBreakpoint
      );
      setChartOptions({ ...options });

      if (chartRef.current) {
        const { chart } = chartRef.current;
        redrawTimeout = setTimeout(() => {
          chart.xAxis[0].isDirty = true;
          chart.redraw();
        }, 500);
      }
    }
    return () => {
      clearTimeout(redrawTimeout);
    };
  }, [indexEvaluationData, screenBreakpoint, rangeSelected]);

  return (
    <div
      className='stock-chart-wrapper no-selectable'
      style={{ width: '100%', height: '100%', marginTop: '-0.6rem' }}
    >
      {chartOptions && (
        <HighchartsReact
          highcharts={Highcharts}
          constructorType={'stockChart'}
          options={chartOptions}
          ref={chartRef}
        />
      )}
    </div>
  );
}

export default forwardRef(StockChart);
