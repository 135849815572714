export const STOCK_CHART_DATE_FORMAT = 'MM/DD/YYYY';
export const STOCK_CHART_DATE_SHORT_FORMAT = 'M/D/YYYY';

export const SCREEN_BREAKPOINTS = {
	XS: 'xs',
	SM: 'sm',
	MD: 'md',
	LG: 'lg',
	XL: 'xl'
};

export const STOCK_CHART_MAX_NUMBER_OFF_YEARS = 17;

export const INDICES = {
	SHILLER_US: 'ShillerUS',
	RPM: 'RPM',
	GLOBAL_SHILLER: 'GlobalShiller',
	SIEGEL: 'Siegel',
	CITI: 'Citi'
};

export const PAGE_NOT_FOUND = [
	'/not-found'
];

