import React from 'react';
import { Container, Navbar } from 'reactstrap';

export const Header = () => {
    return (
        <Navbar light expand='lg' className='secondary-nav'>
        <Container>
          <span>
            Index Explorer
          </span>
        </Container>
      </Navbar>
    );
};