import moment from 'moment';
import { removeTime } from '../../../utils/index';
import {
	SCREEN_BREAKPOINTS,
	STOCK_CHART_DATE_FORMAT,
	STOCK_CHART_DATE_SHORT_FORMAT
} from '../../../constants';
import debounce from 'lodash/debounce';
import { ACTIONS } from './reducer';
import { CHART_FOOTER_DATE_LABELS } from '../../../config';

const showChartFooterDateLabels = CHART_FOOTER_DATE_LABELS === 'true';

function formatDate(date, screenWidth) {
	return moment(date).format(screenWidth === SCREEN_BREAKPOINTS.XS
		? STOCK_CHART_DATE_SHORT_FORMAT
		: STOCK_CHART_DATE_FORMAT);
}

export function drawTicks(screenBreakpoint, startDate, endDate) {
	if (!showChartFooterDateLabels) {
		return;
	}
	const chart = this;
	const margin = 20;
	const processedXData = this.series[0].data.map(d => d.x);
	const index = parseInt(Object.keys(processedXData)[0]);
	const median = processedXData[Math.floor(((processedXData.length - index) / 2) + index)];
	const minDate = startDate || processedXData[index];
	const maxDate = endDate || processedXData[processedXData.length - 1];

	const customTicks = document.querySelectorAll('.custom-ticks');

	customTicks.forEach(element => {
		element.parentNode.removeChild(element);
	});

	chart.renderer.text(
		formatDate(minDate, screenBreakpoint),
		10,
		chart.plotHeight + chart.plotTop + margin
	).attr({
		zIndex: 5,
		class: 'custom-ticks'
	}).add();

	chart.renderer.text(
		formatDate(median, screenBreakpoint),
		(chart.plotWidth / 2) - margin - 30,
		chart.plotHeight + chart.plotTop + margin
	).attr({
		zIndex: 5,
		class: 'custom-ticks median'
	}).add();

	chart.renderer.text(
		formatDate(maxDate),
		chart.plotWidth - 75,
		chart.plotHeight + chart.plotTop + margin
	).attr({
		zIndex: 5,
		class: 'custom-ticks'
	}).add();
}

const drawTicksDebounced = debounce(drawTicks, 200, {
	leading: false,
	trailing: true
});

export const formatChartData = indexEvaluationData => {
	const [pivot] = indexEvaluationData;

	return indexEvaluationData.reduce((prev, curr) => {
		const date = curr.date.valueOf();
		const { minDate, maxDate, data } = prev;

		data.push([date, curr.value]);

		return {
			minDate: minDate < date ? minDate : date,
			maxDate: maxDate > date ? maxDate : date,
			data
		};
	}, { minDate: pivot.date.valueOf(), maxDate: 0, data: [] });
};

function renderTooltip(indexStockTicker) {
	return `<div class='custom-tooltip-wrapper'>
				<div class='first-row'>
					<span class='index-id-dot'><div></div></span>
					<span class='index-id'>${indexStockTicker}</span>&nbsp; &nbsp; &nbsp; 
					<span class='index-date-value'>${moment(this.x).format(STOCK_CHART_DATE_FORMAT)}</span>
				</div>
				<div class='second-row'>
					<span class='index-value-label'>Index Value</span>
					<span class='index-value'>${this.y.toFixed(2)}</span>
				</div>
			</div>`;
}

export function preventExtremesBeyondBoundaries(extremes, dateBoundaries) {
	let { min, max } = extremes;
	const startTime = removeTime(dateBoundaries.min);
	const endTime = removeTime(dateBoundaries.max);
	if (min && max) {
		min = min < startTime ? startTime : removeTime(min);
		max = max > endTime ? endTime : removeTime(max);
	} else if (!min) {
		min = startTime;
	} else if (!max) {
		max = endTime;
	}
	return { min, max };
}

const dispatchNavigatorEdgesDebounced = debounce(({ max, min }, range, dateBoundaries, dispatch) => {
	dispatch({
		type: ACTIONS.DATE_UPDATE,
		state: { min, max, range, dateBoundaries, updatedBy: ACTIONS.UPDATE_BY_NAVIGATOR }
	});
});

export const roundByNum = (num, rounder) => {
	const multiplier = 1 / (rounder || 0.5);
	return Math.round(num * multiplier) / multiplier;
};

export const getChartConfig = (
	{ minDate, maxDate, data },
	dateBoundaries,
	getValidRangeByTarget,
	indexStockTicker,
	dispatchNavigatorState,
	screenBreakpoint
) => {
	const { min, max } = dateBoundaries;

	const navigatorXAxisMax = moment(max).add(1, 'day').valueOf();

	const chartConfig = {
		chart: {
			height: 525,
			marginRight: 60,
			events: {
				load: function () {
					const that = this;
					drawTicksDebounced.call(that, screenBreakpoint);
				}
			}
		},
		scrollbar: {
			enabled: false
		},
		title: false,
		subtitle: false,
		tooltip: {
			useHTML: true,
			split: false,
			padding: 10,
			shadow: true,
			borderColor: '#11175e',
			borderWidth: 1,
			backgroundColor: 'rgba(255, 255, 255, 0.93)',
			formatter: function () {
				const that = this;
				return renderTooltip.call(that, indexStockTicker);
			}
		},
		xAxis: {
			visible: false,
			minRange: 365 * 24 * 60 * 60 * 1000,
			tickPositions: [maxDate, minDate],
			events: {
				setExtremes: function (e) {
					if (e.trigger === 'navigator') {
						const extremes = preventExtremesBeyondBoundaries(e, dateBoundaries);
						const range = getValidRangeByTarget(extremes);
						dispatchNavigatorEdgesDebounced(extremes, range, dateBoundaries, dispatchNavigatorState);
					}
				}
			},
			crosshair: {
				className: 'custom-crosshair',
				color: '#11175e',
				dashStyle: 'LongDash'
			},
			labels: {
				formatter: function () {
					return moment(this.value).format(STOCK_CHART_DATE_FORMAT);
				},
				style: {
					fontSize: '14px',
					color: '#333333',
					fontFamily: 'AvenirNextCyr-Medium'
				}
			}
		},
		yAxis: {
			gridLineWidth: 0,
			minorGridLineWidth: 0,
			crosshair: {
				className: 'custom-crosshair',
				color: '#11175e',
				dashStyle: 'LongDash'
			},
			title: {
				text: 'Index Value',
				x: 37,
				style: {
					fontSize: '14px',
					fontWeight: 'bold',
					color: '#333333',
					fontFamily: 'AvenirNextCyr-Bold'
				}
			},
			labels: {
				x: 37,
				style: {
					fontSize: '14px',
					color: '#333333',
					fontFamily: 'AvenirNextCyr-Medium'
				}
			}
		},
		rangeSelector: {
			inputEnabled: false,
			enabled: false,
			selected: 1
		},
		credits: {
			enabled: false
		},
		navigator: {
			height: 64,
			maskFill: 'rgba(102,133,194,0)',
			outlineWidth: 2,
			outlineColor: '#093f85',
			handles: {
				height: 35,
				symbols: [
					'leftarrow',
					'rightarrow'
				]
			},
			xAxis: {
				width: '100%',
				tickInterval: 30 * 24 * 3600 * 1000 * 12,
				tickPixelInterval: 50,
				type: 'datetime',
				min: removeTime(min),
				max: removeTime(navigatorXAxisMax),
				labels: {
					y: 12,
					x: -12,
					rotation: 0,
					formatter: function () {
						const year = parseInt(new Date(this.value).getFullYear());
						var maxYear = parseInt(new Date(navigatorXAxisMax).getFullYear());
						if (maxYear % 2 !== 0) {
							return year % 2 === 0 ? year.toString() : '';
						}
						else {
							return year % 2 !== 0 ? year.toString() : '';
                        }						
					},
					style: {
						fontSize: '11px',
						color: '#333333',
						fontFamily: 'AvenirNextCyr-Medium'
					}
				}
			}
		},
		plotOptions: {
			area: {
				color: '#899ec3'
			}
		},
		series: [{
			name: 'AAPL Stock Price',
			data: data,
			dataGrouping: {
				enabled: false
			},
			type: 'area',
			threshold: null,
			lineColor: '#093f85',
			lineWidth: 1,
			tooltip: {
				valueDecimals: 2
			},
			marker: {
				symbol: 'url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48ZGVmcz48Y2xpcFBhdGggaWQ9InJ4eDlhIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMCAxM1YwaDEzdjEzem02LjUtMWE1LjUgNS41IDAgMSAwIDAtMTEgNS41IDUuNSAwIDAgMCAwIDExeiIvPjwvY2xpcFBhdGg+PC9kZWZzPjxnPjxnPjxwYXRoIGZpbGw9IiM2NWM4YzYiIGQ9Ik02LjUgMTJhNS41IDUuNSAwIDEgMCAwLTExIDUuNSA1LjUgMCAwIDAgMCAxMXoiLz48cGF0aCBmaWxsPSJub25lIiBzdHJva2U9IiMxMTE3NWUiIHN0cm9rZS1taXRlcmxpbWl0PSIyMCIgc3Ryb2tlLXdpZHRoPSIyIiBkPSJNNi41IDEyYTUuNSA1LjUgMCAxIDAgMC0xMSA1LjUgNS41IDAgMCAwIDAgMTF6IiBjbGlwLXBhdGg9InVybCgmcXVvdDsjcnh4OWEmcXVvdDspIi8+PC9nPjwvZz48L3N2Zz4=)',
				width: 11,
				height: 11
			},
		}],
		responsive: {
			rules: [{
				condition: {
					maxWidth: 575
				},
				chartOptions: {
					chart: {
						height: 470,
						marginLeft: 10
					},
					xAxis: {
						labels: {
							useHTML: true,
							formatter: function () {
								const date = moment(this.value);
								return `<span>
											${date.format('D')}/${date.format('M')}
											<br />
											${date.format('YYYY')}
										</span>`;
							},
							style: {
								fontSize: '14px',
								color: '#333333',
								fontFamily: 'AvenirNextCyr-Medium'
							}
						}
					},
					subtitle: {
						text: null
					},
					navigator: {
						enabled: true,
						xAxis: {
							width: '110%'
						}
					}
				}
			}]
		}
	};
	return chartConfig;
};