import React from 'react';
import Lottie from 'react-lottie';
import { Container, Row, Col, Button } from 'reactstrap';
import * as animationData from './../../assets/magnifying-glass.json';

import './styles.scss';

const NotFound = React.memo(({ history }) => {
  return (
    <div className='not-found-container'>
    <Container>
      <Row>
        <Col xs={12}>
        <div className='animation-container'>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: animationData.default,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice'
                }
              }}
            />
          </div>
        </Col>
      
      </Row>
      <Row className='description-container'>
        <Col xs={12}>
        <h1 className='title'>Page not found</h1>
          <p className='description'>
            The page you’re looking for doesn’t exist or is unavailable.
          </p>
          <p className='description'>
          Try searching for the page from our homepage.
            </p>    
        </Col>
      </Row>
      <Row>
        <Col xs={12} className='btn-container'>
          <Button color='primary' className='btn' onClick={() => history.push('/')}>Go to homepage</Button>
        </Col>
      </Row>
    </Container>
    </div>
  );
});

export default NotFound;