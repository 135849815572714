import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';

import ReactDOM from 'react-dom';

import 'bootstrap/dist/css/bootstrap.css';

import App from './App';

import { AppContextComponent, withContext } from './contexts/appContext';

import { fetchConfigurations, fetchData } from './api/ConfigurationService';

import './styles/main.scss';

const rootElement = document.getElementById('root');

const AppWithContext = withContext()(() => <App />);

(async function () {
	const [configurations, data] = await Promise.all([
		fetchConfigurations(),
		fetchData()
	]);

	ReactDOM.render(
		<AppContextComponent
			configurations={configurations}
			data={data}
		>
			<AppWithContext />
		</AppContextComponent>,
		rootElement);
})();
