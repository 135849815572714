import React from 'react';
import PropTypes from 'prop-types';

function SvgContainer ({ height, width, className = '', viewBox, children }) {
  return (
    <div style={{ width, height }} className={className}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={width}
        height={height}
        viewBox={viewBox === '' ? `0 0 ${width} ${height}` : viewBox}
      >
        {children}
      </svg>
    </div>
  );
}

SvgContainer.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any
};

SvgContainer.defaultProps = {
  viewBox: ''
};

export default SvgContainer;
