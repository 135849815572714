import React from 'react';
import { INDICES } from '../../../../constants';

const FooterContainer = ({ children }) => {
  return (
    <p className='hypothetical-assumptions'>
      <span className='bold'>Hypothetical Assumptions:</span>
      &nbsp;{children}
    </p>
  );
};

const renderBackTestedIndexFooter = (
  {
    index: { description: indexDescription },
    startDate,
    endDate,
    establishedDate
  },
  dateDivider = 'to'
) => {
  return (
    <FooterContainer>
      Index Value of the {indexDescription} from {startDate} {dateDivider}{' '}
      {endDate}. The {indexDescription} was established on {establishedDate}.
      Performance shown before this date is back-tested by applying the index
      strategy, which was designed with the benefit of hindsight, to historical
      financial data. Back-tested performance is hypothetical and has been
      provided for informational purposes only. Past performance is not
      indicative of nor does it guarantee future performance or results. The
      foregoing performance information does not include any relevant costs,
      participation rates and charges associated with fixed indexed annuities or
      any other financial product linked to the {indexDescription}. Fixed
      indexed annuities are not an investment and do not directly invest in the
      stock market or any index. It is not possible to directly invest in an
      index. For more information on fixed indexed annuities and performance of
      the {indexDescription}, ask your insurance professional for an
      illustration.
    </FooterContainer>
  );
};

const renderSP500IndexFooter = ({ startDate, endDate }, dateDivider = 'to') => {
  return (
    <FooterContainer>
      Index Value of the S&P 500® Price Index from {startDate} {dateDivider}{' '}
      {endDate}. Past performance is not indicative of nor does it guarantee
      future performance or results. The foregoing performance information does
      not include any relevant costs, participation rates and charges associated
      with fixed indexed annuities or any other financial product linked to the
      S&P 500® Price Index. Fixed indexed annuities are not an investment and do
      not directly invest in the stock market or any index. It is not possible
      to directly invest in an index. For more information on fixed indexed annuities
      and performance of the S&P 500® Price Index, ask your insurance
      professional for an illustration.
    </FooterContainer>
  );
};

const renderShillerUsIndexFooter = (
    { index: { description: indexDescription }, startDate, endDate },
  dateDivider = 'to'
) => {
  return (
    <FooterContainer>
     Index Value of the {indexDescription} from {startDate} {dateDivider} {endDate}. {indexDescription} was established on 12/2/2020. Performance shown before this date for the {indexDescription} is back-tested by applying the index strategy, which was designed with the benefit of hindsight, to historical financial data. Back-tested performance is hypothetical and has been provided for informational purposes only. Past performance is not indicative of nor does it guarantee future performance or results. The foregoing performance information does not include any relevant costs, participation rates and charges associated with fixed indexed annuities or any other financial product linked to the {indexDescription}. Fixed indexed annuities are not an investment and do not directly invest in the stock market or any index. It is not possible to directly invest in an index. For more information on fixed indexed annuities and performance of the {indexDescription}, ask your insurance professional for an illustration.
    </FooterContainer>
  );
};

const renderRPMIndexFooter = (
  { index: { description: indexDescription }, startDate, endDate },
  dateDivider = 'to'
) => {
  return (
    <FooterContainer>
      Index Value of the {indexDescription} from {startDate} {dateDivider}{' '}
      {endDate}. The Merrill Lynch RPM Index™ was established on 3/1/16.
      Performance shown before this date is back-tested by applying the index
      strategy, which was designed with the benefit of hindsight, to historical
      financial data. Back-tested performance is hypothetical and has been
      provided for informational purposes only. Past performance is not
      indicative of nor does it guarantee future performance or results. The
      foregoing performance information does not include any relevant costs,
      participation rates and charges associated with fixed indexed annuities or
      any other financial product linked to the Merrill Lynch RPM Index™. Fixed
      indexed annuities are not an investment and do not directly invest in the
      stock market or any index. It is not possible to directly invest in an
      index. For more information on fixed indexed annuities and performance of
      the Merrill Lynch RPM Index™, ask your insurance professional for an
      illustration.
    </FooterContainer>
  );
};

const renderCitiGrandmasterIndexFooter = (
    { index: { description: indexDescription }, startDate, endDate },
    dateDivider = 'to'
) => {
    return (
        <FooterContainer>
            Index value of the {indexDescription} Index from {startDate} {dateDivider} {endDate}. All Index performance data prior to April 20, 2021 is hypothetical and back-tested, as the Index did not exist prior to that date. Hypothetical back-tested Index performance data is subject to significant limitations. The Index Administrator developed the rules of the Index with the benefit of hindsight – that is, with the benefit of being able to evaluate how the Index rules would have caused the Index to perform had it existed during the hypothetical back-tested period. The fact that the Index generally appreciated over the hypothetical back-tested period may not therefore be an accurate or reliable indication of any fundamental aspect of the Index methodology. Furthermore, the hypothetical back-tested performance of the Index might look different if it covered a different historical period. The market conditions that existed during the hypothetical back-tested period may not be representative of market conditions that will exist in the future. Moreover, the hypothetical back-tested performance of the Index was based on a particular random distribution of historical returns to construct each hypothetical market simulation, using a different random number generator than the Index currently uses. Each random distribution of returns is different, and so the hypothetical back-tested performance is different from the performance that would have resulted if the Index had actually existed during the back-tested period.
        </FooterContainer>
    );
};

export const FooterParagraph = ({ index }) => {
  const {
    index: { value: indexValue }
  } = index;

  if (indexValue === INDICES.SIEGEL || indexValue === INDICES.GLOBAL_SHILLER) {
    const establishedDate =
      indexValue === INDICES.GLOBAL_SHILLER ? '2/1/19' : '12/3/18';

    return renderBackTestedIndexFooter({ ...index, establishedDate });
  }

  if (indexValue === INDICES.SHILLER_US) {
    return renderShillerUsIndexFooter(index);
  }

  if (indexValue === INDICES.RPM) {
    return renderRPMIndexFooter(index);
  }

  if (indexValue === INDICES.CITI) {
    return renderCitiGrandmasterIndexFooter(index);
  }

  return renderSP500IndexFooter(index);
};
