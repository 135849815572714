import React, { useState, useEffect } from 'react';
import './styles.scss';

export function Select({
	value,
	options = [],
	handleOnChange,
	className = ''
}) {
	const [defaultSelectText, setDefaultSelectText] = useState('');
	const [showOptionList, setShowOptionList] = useState(false);

	const handleOptionClick = e => {
		const description = e.target.getAttribute('data-name');
		const value = e.target.getAttribute('data-value');
		setDefaultSelectText(description);
		setShowOptionList(false);
		handleOnChange && handleOnChange(value);
	};

	const handleClickOutside = e => {
		if (e.target.classList &&
			!e.target.classList.contains('custom-select-option') &&
			!e.target.classList.contains('selected-text')) {
			setShowOptionList(false);
		}
	};

	const handleListDisplay = () => setShowOptionList(!showOptionList);

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);

		const [defaultValue] = !value
			? options
			: options.filter(option => option.value === value);

		setDefaultSelectText(defaultValue.description);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [options, value]);

	return (
		<div className={`custom-select-container ${className} ${showOptionList ? 'show' : 'hidden'}`}>
			<div
				className={showOptionList ? 'selected-text active' : 'selected-text'}
				onClick={handleListDisplay}
			>
				<p>{defaultSelectText}</p>
			</div>
			{showOptionList && (
				<ul className='select-options'>
					{options.map((option, index) => (
						<li
							className='custom-select-option'
							data-value={option.value}
							data-name={option.description}
							key={`option-${index}`}
							onClick={handleOptionClick}
						>
							{option.description}
						</li>
					))}
				</ul>
			)}
		</div>
	);
}