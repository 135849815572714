import { preventExtremesBeyondBoundaries } from './helper';

export const renderOutline = function (Highcharts) {
	Highcharts.wrap(Highcharts.Navigator.prototype, 'drawOutline', function (procced, zoomedMin, zoomedMax, inverted, verb) {
		const navigator = this,
			maskInside = navigator.navigatorOptions.maskInside,
			outlineWidth = navigator.outline.strokeWidth(),
			halfOutline = outlineWidth / 2,
			outlineCorrection = (outlineWidth % 2) / 2, // #5800
			outlineHeight = navigator.outlineHeight,
			scrollbarHeight = navigator.scrollbarHeight,
			navigatorSize = navigator.size;
		let left = navigator.left - scrollbarHeight,
			navigatorTop = navigator.top,
			verticalMin,
			path;

		if (inverted) {
			left -= halfOutline;
			verticalMin = navigatorTop + zoomedMax + outlineCorrection;
			zoomedMax = navigatorTop + zoomedMin + outlineCorrection;

			path = [
				'M',
				left + outlineHeight,
				navigatorTop - scrollbarHeight - outlineCorrection, // top edge
				'a',
				left + outlineHeight,
				verticalMin, // top right of zoomed range
				'L',
				left,
				verticalMin, // top left of z.r.
				'L',
				left,
				zoomedMax, // bottom left of z.r.
				'L',
				left + outlineHeight,
				zoomedMax, // bottom right of z.r.
				'L',
				left + outlineHeight,
				navigatorTop + navigatorSize + scrollbarHeight // bottom edge
			].concat(maskInside ? [
				'M',
				left + outlineHeight,
				verticalMin - halfOutline, // upper left of zoomed range
				'L',
				left + outlineHeight,
				zoomedMax + halfOutline // upper right of z.r.
			] : []);
		} else {
			zoomedMin += left + scrollbarHeight - outlineCorrection;
			zoomedMax += left + scrollbarHeight - outlineCorrection;
			navigatorTop += halfOutline;

			const round = 4;
			const roundOffset = 2 * round;
			const width = zoomedMax - zoomedMin - roundOffset;
			const height = (navigatorTop + outlineHeight) - navigatorTop - roundOffset;

			path = [
				'M', zoomedMin + round, navigatorTop,
				'h', width,
				'a', round, round, 0, 0, 1, round, round,
				'v', height,
				'a', round, round, 0, 0, 1, -round, round,
				'h', -width,
				'a', round, round, 0, 0, 1, -round, -round,
				'v', -height,
				'a', round, round, 0, 0, 1, round, -round,
				'z'
			].concat(maskInside ? [
				'M',
				zoomedMin - halfOutline + round,
				navigatorTop, // upper left of zoomed range
				'L',
				zoomedMax + halfOutline - round,
				navigatorTop // upper right of z.r.
			] : []);
		}
		navigator.outline[verb]({
			d: path
		});

	});
};

export const renderNavigatorHandles = function (Highcharts) {
	Highcharts.SVGRenderer.prototype.symbols.leftarrow = function (x, y, w, h) {
		return [
			//Pill drawer
			'M', -6, 4,
			'a', 4, 4, 0, 0, 1, 4, -4,
			'h', 4,
			'a', 4, 4, 0, 0, 1, 4, 4,
			'v', 30,
			'a', 4, 4, 0, 0, 1, -4, 4,
			'H', -2,
			'a', 4, 4, 0, 0, 1, -4, -4,
			'z',
			//Navigator arrow drawer 
			'M', -2.88, 19.41,
			'L', 1.78, 23.91,
			'L', 2.88, 22.84,
			'L', -0.7, 19.41,
			'L', 2.88, 15.97,
			'L', 1.78, 14.91,
			'L', -2.88, 19.41,
			'Z',
			// Navigator arrow filler
			'M', 1.09, 22.16,
			'L', 2.28, 23.29,
			'L', -2.25, 18.98,
			'L', 1.09, 22.16,
			'Z',
			'M', -2.19, 19.91,
			'L', 2.24, 15.38,
			'L', -2.19, 19.91,
			'Z',
		];
	};


	Highcharts.SVGRenderer.prototype.symbols.rightarrow = function (x, y, w, h) {
		return [
			//Pill drawer
			'M', -6, 4,
			'a', 4, 4, 0, 0, 1, 4, -4,
			'h', 4,
			'a', 4, 4, 0, 0, 1, 4, 4,
			'v', 30,
			'a', 4, 4, 0, 0, 1, -4, 4,
			'H', -2,
			'a', 4, 4, 0, 0, 1, -4, -4,
			'z',

			//Navigator arrow drawer 
			'M', 2.88, 19.41,
			'L', -1.78, 23.91,
			'L', -2.88, 22.84,
			'L', 0.7, 19.41,
			'L', -2.88, 15.97,
			'L', -1.78, 14.91,
			'L', 2.88, 19.41,
			'Z',

			// Navigator arrow filler
			'M', -1.06, 22.16,
			'L', -2.25, 23.29,
			'L', 2.28, 18.98,
			'L', -1.06, 22.16,
			'Z',

			'M', 2.24, 19.91,
			'L', -2.19, 15.38,
			'L', 2.24, 19.91,
			'Z',
		];
	};
	if (Highcharts.VMLRenderer) {
		Highcharts.VMLRenderer.prototype.symbols.leftarrow = Highcharts.SVGRenderer.prototype.symbols.leftarrow;
		Highcharts.VMLRenderer.prototype.symbols.rightarrow = Highcharts.SVGRenderer.prototype.symbols.rightarrow;
	}
};

export function customSetExtremes(H) {
	return function (dateBoundaries) {
		H.wrap(H.Axis.prototype, 'setExtremes', function (proceed) {
			const [, min, max] = arguments;
			const extremes = preventExtremesBeyondBoundaries({ min, max }, dateBoundaries);
			arguments[1] = extremes.min;
			arguments[2] = extremes.max;
			proceed.apply(this, [].slice.call(arguments, 1));
		});
	};
};