import React from 'react';
import { NavMenu } from '../../components/shared/NavMenu';
import { Footer } from '../../components/shared/Footer';

import './Layout.scss';
import { Header } from '../../components/shared/Header';
import { useLocation } from 'react-router-dom';
import { PAGE_NOT_FOUND } from '../../constants';


export function Layout ({ children }) {
  const location = useLocation();
  const isNotFoundPage = PAGE_NOT_FOUND.includes(location.pathname);
  return (
    <div>
      <NavMenu />
      {!isNotFoundPage && <Header/>}
      <div className='layout-content'>
        <div className='container'>{children}</div>
      </div>
      <Footer />
    </div>
  );
}
