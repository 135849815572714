import React from 'react';
import { parseDatesForData } from '../utils';

const AppContext = React.createContext();

export function AppContextComponent({
	children,
	configurations,
	data
}) {
	parseDatesForData(data);

	const state = {
		configurations,
		data
	};

	return (
		<AppContext.Provider value={{ ...state }}>
			{children}
		</AppContext.Provider>
	);
}

export const withContext = (newProps = {}) => ChildComponent => {
	return props => (
		<AppContext.Consumer>
			{context => {
				return <ChildComponent {...context} {...newProps} {...props} />;
			}}
		</AppContext.Consumer>
	);
};