const env = process.env.NODE_ENV;

let config = null;
try {
	config = require(`./env/${env}`);
} catch (ex) { }

module.exports = Object.assign(
	{},
	{
		API_URL: process.env.REACT_APP_API_URL,
		CHART_FOOTER_DATE_LABELS: process.env.REACT_APP_CHART_FOOTER_DATE_LABELS
	},
	config || {}
);
