import React, { useCallback, useEffect, useState } from 'react';
import {
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  Container,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown, UncontrolledDropdown
} from 'reactstrap';

import { AtheneLogo } from '../AtheneLogo';
import { links } from './setupLinks';

import './NavMenu.scss';
import ScreenSize from '../ScreenSize';
import { CollapseArrow } from '../MenuArrow';
import DOMPurify from 'dompurify';

const CreateNavItem = ({ href, text, className, links }, index, isMobile) => {
  const [isOpen, setIsOpen] = useState(false);
  const [collapsedMenu, setCollapsedMenu] = useState(false);
  const toggleNavbarMenu = () => setCollapsedMenu(!collapsedMenu);
  const dropDownItems = () => isMobile ?
    ( 
      <UncontrolledDropdown nav inNavbar key={`drop-item-${index}`}>
        <DropdownToggle nav caret onClick={toggleNavbarMenu}>
          {text}
          <CollapseArrow width={20} height={16} />
        </DropdownToggle>
        <Collapse isOpen={collapsedMenu} navbar>
        <DropdownMenu right>
          {links.map((link, index) => (
            <DropdownItem key={`drop-item-op-${index}`} href={link.href}>
              <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(link.text) }}/>
            </DropdownItem>
          ))}
        </DropdownMenu>
        </Collapse>
      </UncontrolledDropdown>
    ) :
    (
      <Dropdown nav inNavbar key={`drop-item-${index}`}
              isOpen={isOpen}
              toggle={() => setIsOpen(!isOpen)}
              onMouseEnter={() => setIsOpen(true)}
              onMouseLeave={() => setIsOpen(false)}>
        <DropdownToggle nav caret>
          {text}
        </DropdownToggle>
        <DropdownMenu left>
          {links.map((link, index) => (
            <DropdownItem key={`drop-item-op-${index}`} href={link.href}>
              <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(link.text) }}/>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    );
  return !links ? (
    <NavItem
      key={`nav-item-${index}`}
    >
      <NavLink href={href} className={className}>
        {text}
      </NavLink>
    </NavItem>
  ) : (
    dropDownItems()
  );
};

export function NavMenu () {
  const [collapsed, setCollapsed] = useState(false);
  const toggleNavbar = () => setCollapsed(!collapsed);

  const [y, setY] = useState(document.scrollingElement.scrollHeight);
  const [navBarClass, setNavBarClass] = useState('show');
  const [containerBarClass, setContainerBarClass] = useState('scrollTop');

  const screenSize = ScreenSize();
  const isMobile = screenSize.width < 992;

  const handleNavigation = useCallback(() => {
    setNavBarClass(y > window.scrollY ? 'show' : 'hide');
    setContainerBarClass(window.scrollY <= 10 ? 'scrollTop' : 'scrollUp');
    setY(window.scrollY);
  }, [y]);

  useEffect(() => {

    window.addEventListener('scroll', handleNavigation);

    return () => {
      window.removeEventListener('scroll', handleNavigation);
    };
  }, [handleNavigation]);

  useEffect(() => {
    if (!isMobile) {
      setCollapsed(false);
    }
  }, [isMobile]);
  
  return (
    <React.Fragment>
      <Navbar fixed={!isMobile ? 'top' : ''} color='light' light expand='lg' className={!isMobile ? navBarClass : ''}>
        <Container className={!isMobile ? containerBarClass : ''}>
          <NavbarBrand href={links[0].href}>
            <AtheneLogo width={231} height={54} className='logo-container'/>
          </NavbarBrand>
          {isMobile &&
            <>
              <input className='menu-btn' type='checkbox' id='menu-btn'/>
              <label className='menu-icon' htmlFor='menu-btn' onClick={toggleNavbar}>
                <span className='navicon'/>
              </label>
            </>
        }
          <Collapse isOpen={collapsed} navbar>
            <Nav className='ml-auto nav-list' navbar>
              {links.map((link, index) => CreateNavItem(link, index, isMobile))}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </React.Fragment>
  );
}
