import React from 'react';
import './styles.scss';

export function CompoundAnnualReturnSection({
	annualReturnValue
}) {
	return (
		<div className='compound-annual-return-wrapper'>
			<div className='compound-annual-return-container'>
				<span className='annual-return-value'>{annualReturnValue.toFixed(2)} %</span>
				<p className='annual-return-text'>
                    Compound <br /> Annual Return
				</p>
			</div>
			<p className='compound-annual-return-body-text'>
                The compound annual return does not <br />
                represent hypothetical <br />
                or actual returns.
			</p>
		</div>
	);
}