import React from 'react';

import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import { ArrowDivider } from './ArrowDivider';

import './styles.scss';

import { getScreenBreakpoint } from '../../../utils';

import { useWindowResize } from '../../../utils/useWindowResize';

import { SCREEN_BREAKPOINTS } from '../../../constants/index';

export function DateRange({
  startDate,
  onStartDateChange,
  minStartDate,
  maxStartDate,
  endDate,
  minEndDate,
  maxEndDate,
  onEndDateChange,
  disabled
}) {
  const windowDimensions = useWindowResize();
  const screenBreakpoint = getScreenBreakpoint(windowDimensions);
  const isOnMobile = screenBreakpoint === SCREEN_BREAKPOINTS.XS;

  return (
    <>
      <div className='date-range-wrapper'>
        <div className='form-group'>
          <label className='form-label'>Start</label>
          <DatePicker
            disabled={disabled}
            selected={startDate}
            onChange={date => onStartDateChange(date)}
            selectsStart
            startDate={startDate}
            endDate={maxEndDate}
            minDate={minStartDate}
            maxDate={maxStartDate}
            showPopperArrow={false}
            className='custom-date-picker'
            popperModifiers={{
              preventOverflow: {
                enabled: true
              }
            }}
            {...(isOnMobile && { withPortal: true })}
            onFocus={(e) => e.target.readOnly = isOnMobile}
          />
        </div>
        <ArrowDivider />
        <div className='form-group'>
          <label className='form-label'>End</label>
          <DatePicker
            disabled={disabled}
            selected={endDate}
            onChange={date => onEndDateChange(date)}
            selectsEnd
            startDate={startDate}
            endDate={maxEndDate}
            minDate={minEndDate}
            maxDate={maxEndDate}
            showPopperArrow={false}
            className='custom-date-picker'
            popperModifiers={{
              preventOverflow: {
                enabled: true
              }
            }}
            {...(isOnMobile && { withPortal: true })}
            onFocus={(e) => e.target.readOnly = isOnMobile}
          />
        </div>
      </div>
    </>
  );
}