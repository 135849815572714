import React from 'react';
import PropTypes from 'prop-types';
import SvgContainer from '../SvgContainer';

export function MenuArrow({ width, height }) {
  return (
    <SvgContainer width={width} height={height} className='menu-arrow'>
      <svg viewBox='0 0 512 512'>
        <path d='M128 192l128 128 128-128z' fill='#0e0e0e' />
      </svg>
    </SvgContainer>
  );
}

MenuArrow.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired
};

export function CollapseArrow({ width, height }) {
  return (
    <SvgContainer width={width} height={height} className='collapse-arrow'>
      <svg viewBox='0 0 512 512'>
        <path
          d='M512 192l-96-96-160 160-160-160-96 96 256 255.999z'
          fill='#656363'
        />
      </svg>
      ;
    </SvgContainer>
  );
}

CollapseArrow.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired
};
