import React from 'react';
import './Footer.scss';
import { PoweredByAnnexusLogo } from '../PoweredByAnnexusLogo';

export function Footer() {
  return (
    <footer className='footer'>
      <div className='container'>
        <p>
          This annuity has limitations and charges, which include Withdrawal
          Charges, Market Value Adjustment, and penalty free withdrawal
          provisions. For costs and complete details, please request a
          Certificate of Disclosure. Please click on the link to the appropriate
          product for additional information, which includes product specific
          details:{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://s3.amazonaws.com/bca2.0/83151-BCA12-2.0-Additional-Product-Insert.pdf'
          >
            BCA 12 2.0
          </a>
          ,{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://s3.amazonaws.com/bca2.0/83150-BCA10-2.0-Additional-Product-Insert.pdf'
          >
            BCA 10 2.0
          </a>
          ,{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://s3.amazonaws.com/bca2.0/83149-BCA8-2.0-Additional-Product-Insert.pdf'
           >
            BCA 8 2.0
          </a>
          ,{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://s3.amazonaws.com/bca2.0/83148-BCA6-2.0-Additional-Product-Insert.pdf'
          >
            BCA 6 2.0
          </a>
          ,{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://s3.amazonaws.com/bca2.0/87007-Velocity-Additional-Product-Insert.pdf'
          >
            Athene Velocity
          </a>
          . These fixed indexed annuities are not available in all states.
        </p>
        <p>
          This material is not a recommendation to allocate to specific indices
          and is provided only to demonstrate how different strategy allocations
          would have performed. Please contact your insurance professional for
          more information.
        </p>
        <p>
          This material is a general description intended for general public
          use. Athene Annuity and Life Company (61689), headquartered in West
          Des Moines, Iowa, and issuing annuities in 49 states (excluding NY)
          and D.C., and Athene Annuity & Life Assurance Company of New York
          (68039), headquartered in Pearl River, New York, and issuing annuities
          in New York, are not undertaking to provide investment advice for any
          individual or in any individual situation, and therefore nothing in
          this should be read as investment advice. Please reach out to your
          financial professional if you have any questions about Athene products
          or their features. Products not available in all states.
        </p>
        <p>
          The Merrill Lynch RPM Index™ was established on
          03/01/16, the Shiller Barclays Global Index was established 02/01/19,
          and the WisdomTree Siegel Strategic Value Index™ was established
          12/03/18, the Shiller Barclays CAPE® Allocator 6 Index was established
          on 12/02/20, the Citi Grandmaster Index was established on 04/20/21
          and any performance shown before these dates is back-tested by
          applying the index strategy to historical financial data. Backtested
          performance is hypothetical and provided for informational purposes
          only. Past performance is not an indicator or guarantee of future
          results.
        </p>
        <p>
          Please click on the link to the appropriate product for additional
          information:{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://s3.amazonaws.com/bca2.0/83151-BCA12-2.0-Additional-Product-Insert.pdf'
          >
            BCA 12 2.0
          </a>
          ,{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://s3.amazonaws.com/bca2.0/83150-BCA10-2.0-Additional-Product-Insert.pdf'
          >
            BCA 10 2.0
          </a>
          ,{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://s3.amazonaws.com/bca2.0/83149-BCA8-2.0-Additional-Product-Insert.pdf'
          >
            BCA 8 2.0
          </a>
          ,{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://s3.amazonaws.com/bca2.0/83148-BCA6-2.0-Additional-Product-Insert.pdf'
          >
           BCA 6 2.0
          </a>
          ,{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://s3.amazonaws.com/bca2.0/87007-Velocity-Additional-Product-Insert.pdf'
          >
            Athene Velocity
          </a>
          . These fixed indexed annuities are not available in all states.
        </p>
        <p>
          If your needs are short-term or if you will need access to more than
          the contract Free Withdrawal amount, BCA 12 2.0 may not be appropriate
          for your particular financial situation. Consult with your insurance
          professional and carefully consider your financial situation prior to
          purchasing.
        </p>
        <p>
          Citi and Citi and Arc design are trademarks and service marks of Citigroup Inc. or its affiliates, are used and registered throughout the world, and are used under license for certain purposes by Athene Annuity and Life Company or its affiliates (the "Licensee"). Citigroup Global Markets Limited ("Citigroup") has licensed the Citi Grandmaster Index (the "Index") to the Licensee for its sole benefit. Neither the Licensee nor the Licensee's fixed indexed annuity product (the "Product") is sponsored, endorsed, sold or promoted by Citigroup or any of its affiliates. Citigroup makes no representation or warranty, express or implied, to persons purchasing the Product. Such persons should seek appropriate advice before making any purchase. The Index has been designed and is compiled, calculated, maintained and sponsored by Citigroup without regard to Licensee, the Product or any purchaser of the Product. Citigroup is under no obligation to continue sponsoring or calculating the Index. CITIGROUP DOES NOT GUARANTEE THE ACCURACY OR PERFORMANCE OF THE INDEX, THE INDEX METHODOLOGY, THE CALCULATION OF THE INDEX OR ANY DATA SUPPLIED BY CITIGROUP FOR USE IN CONNECTION WITH THE PRODUCT AND DISCLAIMS ALL LIABILITY FOR ANY SPECIAL, INDIRECT, CONSEQUENTIAL DAMAGES EVEN IF NOTIFIED OF THE POSSIBILITY OF SUCH DAMAGES. Please see{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://investmentstrategies.citi.com/cis/us'
          >
            https://investmentstrategies.citi.com/cis/us
          </a>{' '}
          for additional important information about The Citi Grandmaster Index.
        </p>
        <p>
          The Shiller Barclays CAPE® Allocator 6 Index and the Shiller Barclays Global Index (collectively, the "Indices") have been developed in part by RSBB-I, LLC, the research principal of which is Robert J. Shiller. RSBB-I, LLC is not an investment advisor, and does not guarantee the accuracy or completeness of the Indices, or any data or methodology either included therein or upon which they are based. Neither RSBB-I, LLC nor Robert J. Shiller and its consultant, IndexVestLAB, LLC and consultants thereto, shall have any liability for any errors, omissions, or interruptions therein, and makes no warranties, express or implied, as to performance or results experienced by any party from the use of any information included therein or upon which it is based, and expressly disclaims all warranties of merchantability or fitness for a particular purpose with respect thereto, and shall not be liable for any claims or losses of any nature in connection with the use of such information, including but not limited to, lost profits or punitive or consequential damages, even if RSBB-I, LLC is advised of the possibility of same.
        </p>
        <p>
          Neither Barclays Bank PLC ("BB PLC") nor any of its affiliates (collectively "Barclays") is the issuer or producer of Athene Annuity and Life Company's ("Athene") fixed index annuities (the "Products") and Barclays has no responsibilities, obligations or duties to purchasers of the Products. The Indices, together with any Barclays indices that are components of the Indices, are trademarks owned by Barclays and, together with any component indices and index data, are licensed for use by Athene as the issuer or producer of the Products (the "Issuer").
        </p>
        <p>
          Barclays' only relationship with the Issuer in respect of the Indices is the licensing of the Indices, which are administered, compiled and published by BB PLC in its role as the index sponsor (the "Index Sponsor") without regard to the Issuer or the Products or purchasers of the Products. Additionally, Athene as issuer or producer of the Products may for itself execute transaction(s) with Barclays in or relating to the Indices in connection with the Products. Consumers acquire the Products from Athene and neither acquire any interest in the Indices nor enter into any relationship of any kind whatsoever with Barclays upon purchasing the Products. The Products are not sponsored, endorsed, sold or promoted by Barclays and Barclays makes no representation regarding the advisability of the Products or use of the Indices or any data included therein. Barclays shall not be liable in any way to the Issuer, purchasers of the Products or to other third parties in respect of the use or accuracy of the Indices or any data included therein.
        </p>
        <p>
          The WisdomTree Siegel Strategic Value Index™ (the "Index") is the
          exclusive property of Canadian Imperial Bank of Commerce (Canadian
          Imperial Bank of Commerce, together with its affiliates, "CIBC"). CIBC
          has engaged Bloomberg Finance L.P. ("Bloomberg") to maintain and to
          make certain calculations related to the Index. "Canadian Imperial
          Bank of Commerce", "CIBC" and "Index" (collectively, the "CIBC Marks")
          are trademarks or service marks of CIBC. CIBC has licensed use of the
          Index and the CIBC Marks to Athene Annuity and Life Company ("Athene")
          for use in one or more fixed indexed annuities offered by Athene (the
          "Product(s)"). CIBC is not the issuer of the Products and its sole
          contractual relationship with Athene is to license the Index and the
          CIBC Marks to Athene. CIBC, Jeremy Siegel ("Siegel"), senior research
          advisor to WisdomTree Investments, Inc. ("WisdomTree"), and WisdomTree
          each contributed to the development of the index without considering
          the needs of Athene or any annuitant. Neither CIBC, Siegel nor
          WisdomTree make any representation or warranty, express or implied,
          regarding the Index or its development and have no responsibilities,
          obligations or liabilities with respect to the inception, adjustment,
          maintenance, operation or calculation. None of CIBC, WisdomTree,
          Bloomberg or Siegel are affiliated with each other or control or are
          controlled by each other. "WisdomTree®" is a registered trademark of
          WisdomTree. WisdomTree and Siegel have licensed certain rights to CIBC
          to use their names in connection with the Index.
        </p>
        <p>
          None of CIBC, Bloomberg, Siegel and WisdomTree or any other
          third-party licensor (collectively, the "Index Parties") to CIBC is
          acting, or has been authorized to act, as an agent of Athene or has in
          any way sponsored, promoted, solicited, negotiated, endorsed, offered,
          sold, issued, supported, structured or priced any Products or provided
          investment advice to Athene. No Index Party is a fiduciary or agent of
          any purchaser, seller or holder of any Product, or has made any
          representation or warranty, express or implied, regarding the
          advisability of purchasing, selling or holding any Product or the
          ability of the Index to track corresponding or relative market
          performance. Purchasers of any Product neither acquire any interest in
          the Index nor enter into any relationship of any kind whatsoever with
          any of the Index Parties. No Index Party guarantees the timeliness,
          accurateness, or completeness of the Index or any data or information
          relating thereto and shall have no liability in connection with the
          Index or any data or information relating thereto. No Index Party
          shall have any liability with respect to any Product, nor any
          liability for any loss relating to any Product, whether arising
          directly or indirectly from the use of the Index, its methodology, or
          otherwise. The selection of the Index as a crediting option under any
          Product does not obligate Athene to invest annuity premiums in the
          components of the Index. Any obligation to invest annuity premiums
          received under the Products are determined solely by Athene.
        </p>
        <p>
          BLOOMBERG is a trademark and service mark of Bloomberg. Bloomberg and
          its affiliates are not affiliated with Athene or CIBC. Bloomberg's
          association with CIBC is to act as the administrator and calculation
          agent of the Index, which is the property of CIBC. Bloomberg does not
          guarantee the timeliness, accurateness, or completeness of the Index
          or any data or information relating thereto and shall have no
          liability in connection with the Index or any data or information
          relating thereto.
        </p>
        <p>
          In calculating the level of the Index, the index methodology deducts a
          maintenance fee of 0.20% per year, calculated daily. This fee will
          reduce the level of the Index and thus the amount of interest, if any,
          that will be credited to any Product. Furthermore, while the
          volatility control applied by CIBC as part of the index methodology
          may result in less fluctuation in rates of return as compared to
          indices without volatility controls, it may also reduce the overall
          rate of return for products referencing the Index as compared to other
          indices not subject to volatility controls.
        </p>
        <p>
          Athene Annuity and Life Company's Product ("Product") is not
          sponsored, endorsed, sold or promoted by BofAS Securities, Inc., or
          its affiliates ("BofAS"). BofAS has not passed on the legality or
          suitability of, or the accuracy or adequacy of descriptions and
          disclosures relating to, the Product, nor makes any representation or
          warranty, express or implied, to the owners of Product or any member
          of the public regarding the Product or the advisability of purchasing
          the Product, particularly the ability of the Merrill Lynch RPM Index™
          ("Index") to track performance of any market or strategy. BofAS's
          only relationship to Athene Annuity and Life Company ("Licensee") is
          the licensing of certain trademarks and trade names and the Index or
          components thereof. The Index is determined, composed and calculated
          by BofAS without regard to the Licensee or the Product or its holders.
          BofAS has no obligation to take the needs of the Licensee or the
          holders of the Product into consideration in determining, composing or
          calculating the Index. BofAS is not responsible for and has not
          participated in the determination of the timing of, prices of, or
          quantities of the Product to be issued or in the determination or
          calculation of the equation by which the Product is to be priced,
          sold, or purchased, or redeemed. BofAS has no obligation or liability
          in connection with the administration or marketing of the Product.
        </p>
        <p>
          BOFAS DOES NOT GUARANTEE THE ACCURACY AND/OR THE COMPLETENESS OF THE
          INDEX OR ANY DATA INCLUDED THEREIN AND BOFAS SHALL HAVE NO LIABILITY
          FOR ANY ERRORS, OMISSIONS, UNAVAILABILITY, OR INTERRUPTIONS THEREIN.
          BOFAS MAKES NO WARRANTY, EXPRESS OR IMPLIED, AS TO RESULTS TO BE
          OBTAINED BY LICENSEE, HOLDERS OF THE PRODUCT OR ANY OTHER PERSON OR
          ENTITY FROM THE USE OF THE INDEX OR ANY DATA INCLUDED THEREIN. BOFAS
          MAKES NO EXPRESS OR IMPLIED WARRANTIES AND EXPRESSLY DISCLAIMS ALL
          WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OR
          USE, WITH RESPECT TO THE INDEX OR ANY DATA INCLUDED THEREIN. WITHOUT
          LIMITING ANY OF THE FOREGOING, IN NO EVENT SHALL BOFAS HAVE ANY
          LIABILITY FOR ANY SPECIAL, PUNITIVE, INDIRECT, INCIDENTAL,
          CONSEQUENTIAL DAMAGES, OR LOST PROFITS, EVEN IF NOTIFIED OF THE
          POSSIBILITY OF SUCH DAMAGES.
        </p>
        <p>
          "BofA Merrill Lynch®" and the "Merrill Lynch RPM Index™" are
          trademarks of BofAS or its affiliates and have been licensed for use
          by Athene Annuity and Life Company.
        </p>
        <p>
          The Merrill Lynch RPM Index™ is the property of BofAS, which has
          contracted with S&P Opco, LLC (a subsidiary of S&P Dow Jones Indices
          LLC) to calculate and maintain the Index. The Index is not sponsored
          by S&P Dow Jones Indices or its affiliates or its third party
          licensors (collectively, "S&P Dow Jones Indices"). S&P Dow Jones
          Indices will not be liable for any errors or omissions in calculating
          the Index. "Calculated by S&P Dow Jones Indices" and the related
          stylized mark(s) are service marks of S&P Dow Jones Indices and have
          been licensed for use by Licensee.
        </p>
        <p>
          The "S&P 500®" is a product of S&P Dow Jones Indices LLC, a division
          of S&P Global, or its affiliates ("SPDJI") and has been licensed for
          use by Athene Annuity and Life Company. Standard & Poor's® and S&P®
          are registered trademarks of Standard & Poor's Financial Services LLC,
          a division of S&P Global ("S&P"); Dow Jones® is a registered trademark
          of Dow Jones Trademark Holdings LLC ("Dow Jones"); and these
          trademarks have been licensed for use by SPDJI and sublicensed for
          certain purposes by Athene Annuity and Life Company. Athene Annuity
          and Life Company's Products are not sponsored, endorsed, sold or
          promoted by SPDJI, Dow Jones, S&P, their respective affiliates and
          none of such parties make any representation regarding the
          advisability of investing in such product(s) nor do they have any
          liability for any errors, omissions, or interruptions of the S&P 500®.
        </p>
        <p>
          Athene BCA 2.0 ANN19 (01/19), ANN19CS08 (01/19), ANNCS10 (01/19),
          ANN19CS12 (01/19) or state variation, are issued by Athene Annuity
          and Life Company, West Des Moines, IA. Product features, limitations
          and availability vary; see Certificate of Disclosure for full details.
          Products not available in all states.
        </p>
        <p>
          ATHENE ANNUITIES ARE PRODUCTS OF THE INSURANCE INDUSTRY AND NOT
          GUARANTEED BY ANY BANK NOR INSURED BY FDIC OR NCUA/NCUSIF. MAY LOSE
          VALUE. NO BANK/CREDIT UNION GUARANTEE. NOT A DEPOSIT. NOT INSURED BY
          ANY FEDERAL GOVERNMENT AGENCY. MAY ONLY BE OFFERED BY A LICENSED
          INSURANCE AGENT.
        </p>
        <div className='footer-divider'></div>
        <p>
          <span className='text-right'>(2/24)</span>
        </p>
      </div>
    </footer>
  );
}
