import React, { useEffect, useState, useCallback, createRef } from 'react';
import moment from 'moment';
import { CompoundAnnualReturnSection } from '../../components/shared/CompoundAnnualReturnSection';
import { DateRange } from '../../components/shared/DateRange';
import { Preset } from '../../components/shared/Preset';
import { withContext } from '../../contexts/appContext';
import { Select } from '../../components/shared/Select';
import { FooterParagraph } from './components/FooterParagraph';
import StockChart from '../../components/shared/StockChart';
import {
  searchSegmentByRange,
  searchCompoundAnnualReturn,
  isWeekend
} from '../../utils';
import { getCompoundAnnualReturn } from '../../api/IndexEvaluationService';

import './styles.scss';

export function Home ({
  configurations: { indexes, presets, ranges, initialValues },
  configurations,
  data,
}) {
  const [indexEvaluationData, setIndexEvaluationData] = useState(null);
  const [annualReturnValue, setAnnualReturnValue] = useState(0);
  const [indexSelected, setIndexSelected] = useState(
    initialValues.defaultIndex
  );
  const [rangeSelected, setRangeSelected] = useState(
    initialValues.defaultRange
  );
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [minStartDate, setMinStartDate] = useState(null);
  const [maxStartDate, setMaxStartDate] = useState(null);
  const [minEndDate, setMinEndDate] = useState(null);
  const [maxEndDate, setMaxEndDate] = useState(null);
  const stockChartRef = createRef(null);
  const [presetSelected, setPresetSelected] = useState('');
  const [isPresetClicked, setIsPresetClicked] = useState(undefined);

  const staticEndDate = '12/31/2021';

  const getAvailableDates = useCallback(
    currentIndex => {
      return data[currentIndex || indexSelected].map(item => item.date);
    },
    [data, indexSelected]
  );

  const getIndexData = useCallback(() => {
    return data[indexSelected];
  }, [data, indexSelected]);

  const getStartAndEndDate = useCallback(
    currentIndex => {
      const datesAvailable = getAvailableDates(currentIndex);
      const maxStartEndDates = getMaxStarEndDate(currentIndex);
      const [start] = rangeSelected === '-1' ? [ moment(maxStartEndDates.start) ] : datesAvailable;
      const end = rangeSelected === '-1' ? moment(maxStartEndDates.end) : datesAvailable[datesAvailable.length - 1];

      return {
        start: start.toDate(),
        end: end.toDate()
      };
    },
    [getAvailableDates, rangeSelected]
  );

  const getStartDate = (end, years) => {
    let startDate = moment(end)
      .clone()
      .add(-years, 'years');

    let result = startDate.toDate();
    const dates = getStartAndEndDate();
    if (result.getTime() < dates.start.getTime()) {
      result = dates.start;
    }
    return result;
  };

  const getEndDate = (start, years) => {
    let endDate = moment(start)
      .clone()
      .add(years, 'years');

    let result = endDate.toDate();
    const dates = getStartAndEndDate();
    if (result.getTime() > dates.end.getTime()) {
      result = dates.end;
    }
    return result;
  };

  useEffect(() => {
    const { start, end } = getStartAndEndDate();
    const [defaultStartDate, defaultEndDate] = initialValues.defaultDateRange;
    const initialEndDate = new Date(defaultEndDate);
    setStartDate(new Date(defaultStartDate));
    setEndDate(initialEndDate);
    setMinStartDate(start);
    const maxStartDate = moment(end)
      .add(-rangeSelected, 'years')
      .toDate();
    setMaxStartDate(maxStartDate);
    setMinEndDate(maxStartDate);
    setMaxEndDate(end);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      let start = moment(startDate);
      let end = moment(endDate);

      if (rangeSelected === '-1') {
        const dates = getStartAndEndDate();
        start = moment(dates.start);
        end = moment(dates.end);
      }

      const filters = {
        source: data,
        index: indexSelected,
        startDate: start.format('MM/DD/YYYY'),
        endDate: end.format('MM/DD/YYYY'),
        yearRange: rangeSelected,
        preset: presetSelected
      };

      const fetchData = async () => {
          const value = await getCompoundAnnualReturn(filters);
          setAnnualReturnValue(value);
      };
      fetchData();
    }
  }, [
    indexSelected,
    rangeSelected,
    startDate,
    endDate,
    data,
    getStartAndEndDate
  ]);

  const handleOnIndexChange = value => {
    const { start: minStart, end } = getStartAndEndDate(value);
    if (value?.toUpperCase().trim() !== indexSelected?.toUpperCase().trim()) {
      setPresetSelected('');
      setIsPresetClicked(false);
    }
    if (rangeSelected === '-1') {
      setStartDate(minStart);
      setEndDate(end);
      setIndexSelected(value);
      return;
    }
    setMinStartDate(minStart);
    if (minStart.getTime() > startDate.getTime()) {
      setStartDate(minStart);
    }
    setIndexSelected(value);
  };

  const handleOnRangeChange = value => {
    if (value !== rangeSelected) {
      setPresetSelected('');
      setIsPresetClicked(false);
    }

    setRangeSelected(value);

    const { start, end } = getStartAndEndDate();

    if (value === '-1') {
      setStartDate(start);
      setEndDate(end);
      return;
    }

    let endDate = getEndDate(startDate, value);
    setEndDate(endDate);
    const minMax = getStartDate(endDate, value);
    if (minMax.getTime() < startDate.getTime()) {
      setStartDate(minMax);
    }
    setMinEndDate(minMax);
  };

  const handleOnStartDateChange = value => {
    if (!value) return;
    setStartDate(value);
    const endDate = getEndDate(value, rangeSelected);
    setEndDate(endDate);
    setMinEndDate(
      moment(endDate)
        .add(-rangeSelected, 'years')
        .toDate()
    );
  };

  const handleOnEndDateChange = value => {
    if (!value) return;
    setEndDate(value);
    const startDate = getStartDate(value, rangeSelected);
    setStartDate(startDate);
    const endDate = getEndDate(startDate, rangeSelected);
    setMinEndDate(endDate);
  };

  const handleOnPresetChange = (value, isBestPeriod, description) => {
    const foundPreset = presets.find(item => item.value === value);

    if (foundPreset.indexConfiguration) {
      const period = isBestPeriod
        ? foundPreset.indexConfiguration.highest
          : (description === 'Most Recent' ?
              foundPreset.indexConfiguration.mostRecent : foundPreset.indexConfiguration.lowest
          );

      const rangeToFind = rangeSelected !== '-1' ? rangeSelected : '10';

      foundPreset.configuration = {
        ...period.find(obj => `${obj.range}` === `${rangeToFind}`),
        range: rangeSelected
      };
    }

    const {
      configuration: {
        range,
        dates: [start, end]
      }
    } = foundPreset;

    setRangeSelected(range.toString());
    setStartDate(moment(start).toDate());
    setEndDate(moment(end).toDate());
    setPresetSelected(description);
    setIsPresetClicked(true);

    if (stockChartRef.current) {
      stockChartRef.current.setPreset({
        min: moment(start).valueOf(),
        max: moment(end).valueOf(),
        range
      });
    }
  };

  const renderPresets = () => {
    const filteredPreset = presets
      .filter(obj => obj.indexConfiguration && obj.value === indexSelected)
      .reduce((acc, curr) => {
        const bestPeriod = {
          ...curr,
          description: 'Best Period',
          isBest: true,
          disabled: rangeSelected === '-1'
        };
        const worstPeriod = { ...curr, description: 'Worst Period', disabled: rangeSelected === '-1' };
        const mostRecent = { ...curr, description: 'Most Recent',disabled: rangeSelected === '-1' };

        acc.push(bestPeriod, worstPeriod, mostRecent);

        return acc;
      }, []);

    const slicedPresets = presets.slice(0, 1);
    slicedPresets.splice(1, 0, ...filteredPreset);

    return slicedPresets.map((item, i) => {
      const props = { ...item, key: i, handleOnChange: handleOnPresetChange, isPresetActive: item?.description?.toUpperCase()?.trim() === presetSelected?.toUpperCase()?.trim() };

      return <Preset {...props} />;
    });
  };

  const formatDate = date => moment(date).format('MM/DD/YY');

  const selectedIndex = {
    index: indexes.find(({ value }) => value === indexSelected),
    startDate: formatDate(startDate),
    endDate: formatDate(endDate)
  };

  const getMaxStarEndDate = useCallback(
    currentIndex => {
      const dates = presets.find(i => i.value === (currentIndex || indexSelected)).indexConfiguration.max[0].dates;
      return { start: dates[0], end: dates[1] };
    },
    [data, indexSelected]
  );

  return (
    <div className='home-wrapper'>
      <div className='row'>
        <div className='col-12 col-sm-8 col-md-8 col-lg-5'>
          <div className='form-group'>
            <label className='form-label'>Index</label>
            <Select
              value={indexSelected}
              options={indexes}
              handleOnChange={handleOnIndexChange}
            />
          </div>
        </div>
        <div className='col-4 col-sm-3 col-md-2 col-lg-2'>
          <div className='form-group'>
            <label className='form-label'>Range</label>
            <Select
              className='range-select'
              value={rangeSelected}
              options={ranges}
              handleOnChange={handleOnRangeChange}
            />
          </div>
        </div>
        <div className='col-12 col-sm-8 col-lg-3'>
          <DateRange
            disabled={rangeSelected === '-1'}
            startDate={startDate}
            onStartDateChange={handleOnStartDateChange}
            endDate={endDate}
            onEndDateChange={handleOnEndDateChange}
            minStartDate={getStartAndEndDate().start}
            maxStartDate={getStartAndEndDate().end}
            minEndDate={getStartAndEndDate().start}
            maxEndDate={getStartAndEndDate().end}
          />
        </div>
      </div>
      <div className='row layout-presets'>
        <div className='col-12'>
          <div className='form-group'>
            <label className='form-label presets-label'>
              Preset time periods
            </label>
            {renderPresets()}
          </div>
        </div>
      </div>
      <div className='row layout-chart-section'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3'>
          <CompoundAnnualReturnSection annualReturnValue={annualReturnValue} />
        </div>
        <div className='stock-chart-row col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9'>
          {startDate && endDate && (
            <StockChart
              ref={stockChartRef}
              configurations={configurations}
              endDate={endDate}
              startDate={startDate}
              setEndDate={setEndDate}
              setStartDate={setStartDate}
              indexSelected={indexSelected}
              rangeSelected={rangeSelected}
              setRangeSelected={setRangeSelected}
              indexEvaluationData={getIndexData()}
              dateBoundaries={getStartAndEndDate()}
              setPresetSelected={setPresetSelected}
              isPresetClicked={isPresetClicked}
              setIsPresetClicked={setIsPresetClicked}
            />
          )}
        </div>
      </div>
      <div className='row hypothetical-assumptions-section'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 offset-lg-3 offset-xl-3'>
          {startDate && endDate && <FooterParagraph index={selectedIndex} />}
        </div>
      </div>
    </div>
  );
}

export default withContext()(Home);
