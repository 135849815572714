import { doRequest } from '../utils/apiHelper';

export async function getCompoundAnnualReturn({
    index,
    startDate,
    endDate,
    yearRange,
    preset  
}) {
    try {
        const response = await doRequest({
            url: 'api/IndexEvaluation/GetAnnualReturnValue',
            method: 'GET',
            params: {
                index: index,
                startDate: startDate,
                endDate: endDate,
                yearRange: yearRange,
                preset: preset
            }
        });
        return response;
    } catch (e) {
        return null;
    }
    
}